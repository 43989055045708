import React from 'react';
import './Offering.css'; 

const Offering = () => {
  return (
    <div id="our-offer" className="offering-container">
      <h2 className="offering-title">What we offer</h2>
      <div className="cards-container"> 
        <div className="card">
          <img src="./fast-ship.png" alt="Outside regular pace" className="card-image"/>
          <div className="card-text">Rapid validation and execution of opportunities outside the regular pace of the business.</div>
        </div>
        <div className="card">
          <img src="./structure.png" alt="Outside regular structure" className="card-image"/>
          <div className="card-text">By operating outside the usual corporate structure, you avoid the delays and red tape that can hinder innovation in large companies.</div>
        </div>
        <div className="card">
          <img src="./risk-innovation1.png" alt="Risk management" className="card-image"/>
          <div className="card-text">We provide a structured approach to assessing the viability of projects, managing the risk.</div>
        </div>
      </div>
    </div>
  );  
};

export default Offering;