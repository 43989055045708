import React, { useState, useEffect, useRef } from 'react';
import './Hero.css';

function Hero() {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const navRef = useRef();
  const hamburgerRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (navRef.current && !navRef.current.contains(event.target) &&
          hamburgerRef.current && !hamburgerRef.current.contains(event.target)) {
        setIsNavVisible(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsNavVisible(false);
      }
    };

    const handleScroll = () => {
      setScrolled(window.scrollY > 200);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const headerStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.850), rgba(0, 0, 0, 0.750)), url(./hero-background.jpg)`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100vh',
  };

  return (
    <header id="home" style={headerStyle} className={`header ${scrolled ? 'scrolled' : ''}`}>
      <div className={`top-section ${scrolled || isNavVisible ? 'top-scrolled' : ''}`}>
        <div className="logo"></div>
        <nav ref={navRef}>
          <div
            ref={hamburgerRef}
            className="hamburger-menu"
            onClick={() => setIsNavVisible(!isNavVisible)}
          >
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <ul className={`nav-links ${isNavVisible ? 'nav-active' : ''}`}>
            <li><a href="#home" className="keyword">Home</a></li>
            <li><a href="#services" className="keyword">Services</a></li>
            <li><a href="#about" className="keyword">About</a></li>
          </ul>
        </nav>
      </div>
      <div className="hero-section">
        <div className="intro-text">
          <h1 className="title">Antifragile Technology Solutions</h1>
          <p className="subtitle">Where every tech challenge finds its solution.</p>
        </div>
      </div>
    </header>
  );
}

export default Hero;
