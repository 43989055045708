import React from 'react';
import Hero from './components/Hero/Hero';
import Services from './components/Services/Services'
import Offering from './components/Services/Offering'
import About from './components/About/About'
import Packages from './components/Services/Packages'
import Footer from './components/Footer/Footer'

function App() {
  return (
    <div className='App'>
      <Hero />
      <Services />
      <Offering />
      <Packages />
      <About />
      <Footer />
    </div>
  );
}

export default App;
