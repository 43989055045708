import React from 'react';
import './About.css';

function About() {
  return (
    <div className="about" id="about">
        <div className="about-image">
            <img id="profile" src="./about-img.jpg" alt="profile-img" />
            {/* Social Links */}
            <h4>André M. Carvalho</h4>
          <div className="social-links">
            <a href="https://www.linkedin.com/in/andremgc/" target="_blank" rel="noopener noreferrer">
              <img src="./linkedin.png" alt="Linkedin" className="social-icon"/>
            </a>
            <a href="https://andremiguel.substack.com/" target="_blank" rel="noopener noreferrer">
              <img src="./substack-icon.png" alt="Substack" className="social-icon"/>
            </a>
          </div>
        </div>
    </div>
  );
}

export default About;
