import React from 'react';
import './Packages.css';

const Packages = () => {
  return (
    <div id="packages" className="packages-section">
      <h2 className="packages-title">How we deliver</h2> {/* Add the h2 heading */}
      <div className="packages-container"> {/* Add this wrapper for packages */}
        <div className="package">
          <h3>Package One</h3>
          <div className="package-text">On-going consulting sessions to validate opportunities as they appear.</div>
        </div>
        <div className="package">
          <h3>Package Two</h3>
          <div className="package-text">Project-based requirements, roadmap and execution for the validated opportunities.</div>
        </div>
      </div> {/* Close the packages wrapper */}
    </div>
  );
};

export default Packages;

