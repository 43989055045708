import React from 'react';
import './Footer.css'; 

function Footer() {
  const year = new Date().getFullYear(); // Dynamically gets the current year

  return (
    <footer className="footer">
      © {year} Antifragile. All rights reserved.
    </footer>
  );
}

export default Footer;
