import React from 'react';
import './Services.css';

const Services = () => {
    return (
      <div className="services-section" id='services'>
        <div className="services-container">
        <div className="services-column">
          <img src="innovation-icon.png" alt="innovation icon" className='subheading-icon' />
            <h3 className="services-subheading">Product and Innovation</h3>
            <ul className="services-list">
              <li>
                <img src="quadrado.png" alt="." />
                <span>Innovation Risk Management</span>
              </li>
              <li>
                <img src="quadrado.png" alt="." />
                <span>Product Roadmap</span>
              </li>
              <li>
                <img src="quadrado.png" alt="." />
                <span>Software Project Execution</span>
              </li>
            </ul>
          </div>
          <div className="services-column">
          <img src="ai-icon.png" alt="ai icon" className='subheading-icon' />
            <h3 className="services-subheading">Automation</h3>
            <ul className="services-list">
              <li>
                <img src="quadrado.png" alt="." />
                <span>Process Automation</span>
              </li>
              <li>
                <img src="quadrado.png" alt="." />
                <span>Systems Integrations</span>
              </li>
              <li>
                <img src="quadrado.png" alt="." />
                <span>AI-based Automation</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
};

export default Services;